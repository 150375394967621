import React from "react";

const NotFound = () => {
  return (
    <>
      <h3 style={{ textAlign: "center", marginTop: "5rem" }}>
        Are you lost ? 404 Not Found
      </h3>
    </>
  );
};

export default NotFound;
